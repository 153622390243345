import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
// import localForage from 'localforage';

import * as Realm from "realm-web";
const app = new Realm.App({ id: process.env.VUE_APP_STITCH_APP });
const dbName = process.env.VUE_APP_STITCH_DB;

const {
  BSON: { ObjectId },
} = Realm;

const db = function () {
  const mongo = app.currentUser.mongoClient("cluster-antheos");
  return mongo.db(dbName);
};

const loadCollection = function (collection, query = {}) {
  return db()
    .collection(collection)
    .find(query, { sort: { order: 1 } });
};

const processNumberTypes = function (payload) {
  for (const k in payload) {
    if (Object.hasOwn(payload, k)) {
      if (payload[k] === "") {
        delete payload[k];
      }
      if (typeof payload[k] === "object") {
        payload[k] = processNumberTypes(payload[k]);
      } else if (
        typeof payload[k] === "string" &&
        payload[k].match(/^\d+\.\d+$/)
      ) {
        payload[k] = { $numberDouble: payload[k] };
      }
    }
  }
  return payload;
};

Vue.use(Vuex);

export function logIn(creds) {
  return new Promise((resolve, reject) => {
    const credentials = Realm.Credentials.emailPassword(
      creds.email,
      creds.password,
    );

    app
      .logIn(credentials)
      .then((user) => {
        // Authenticate the user
        // `App.currentUser` updates to match the logged in user
        if (user.id !== app.currentUser.id) {
          throw "Current user id doesn't match.";
        }
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loginAnonymous() {
  // Create an anonymous credential
  return new Promise((resolve, reject) => {
    const credentials = Realm.Credentials.anonymous();
    // Authenticate the user
    app
      .logIn(credentials)
      .then((user) => {
        // `App.currentUser` updates to match the logged in user
        if (user.id !== app.currentUser.id) {
          throw "Current user id doesn't match.";
        }

        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signUp(email, password) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .registerUser({ email, password })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendResetLink(email) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .sendResetPasswordEmail({ email })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function resetPassword(password, token, tokenId) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .resetPassword({ token, tokenId, password })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function confirmEmail(token, tokenId) {
  return new Promise((resolve, reject) => {
    app.emailPasswordAuth
      .confirmUser({ token, tokenId })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function currentUser() {
  return app.currentUser;
}

const vuexStorage = new VuexPersist({
  key: "sourcelink",
  storage: window.localStorage,
  // storage: localForage,
  // asyncStorage: true,
  // You can change this explicitly use
  // either window.localStorage  or window.sessionStorage
  // However we are going to make use of localForage
});

export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  state: {
    step: null,
    currentPage: null,
    solution: null,
    objectTypes: {},
    categories: [],
    indicators: [],
    translations: {},
    language: "hu",
    roomId: "",
    code: "",
  },

  mutations: {
    auth(state, user) {
      state.user = user;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setStep(state, step) {
      state.step = step;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setRoomId(state, roomId) {
      state.roomId = roomId;
    },
    setCode(state, code) {
      state.code = code;
    },
    setSolution(state, solution) {
      state.solution = solution;
    },
    setIndicators(state, indicators) {
      state.indicators = indicators;
    },
    setObjectTypes(state, types) {
      state.objectTypes = types;
    },
    setCategories(state, types) {
      state.categories = types;
    },
    setTranslations(state, translations) {
      state.translations = translations;
    },
  },
  actions: {
    resetUserState({ commit }) {
      commit("setCurrentProject", null);
      commit("setCode", null);
      commit("setRoomId", null);
      commit("setStep", null);
      commit("setSites", {});
      commit("setScenarios", {});
      commit("setScenario", null);
      commit("setSolution", null);
    },
    async loadObjectTypes({ commit }) {
      const types = await loadCollection("objectTypes");
      const objectTypes = {};
      for (const t of types) {
        objectTypes[t._id] = t;
      }
      commit("setObjectTypes", objectTypes);
      return objectTypes;
    },
    async loadCategories({ commit }) {
      const categories = await loadCollection("categories");
      commit("setCategories", categories);
      return categories;
    },

    async loadIndicators({ commit }) {
      const indicators = loadCollection("indicators");
      commit("setIndicators", indicators);
      return indicators;
    },

    async loadTranslations({ commit, state }) {
      const translations = await loadCollection("translations");
      commit(
        "setTranslations",
        Object.groupBy(translations, (i) => {
          return i.language ?? "en";
        }),
      );
      return translations;
    },

    async loadSolutionByCode(_store, code) {
      const solution = await db()
        .collection("solutions")
        .aggregate([
          {
            $match: { code: code },
          },
          {
            $lookup: {
              from: "projects",
              localField: "projectId",
              foreignField: "_id",
              as: "project",
            },
          },
          { $unwind: "$project" },
          {
            $lookup: {
              from: "scenarios",
              localField: "scenarioId",
              foreignField: "_id",
              as: "scenario",
            },
          },
          { $unwind: "$scenario" },
          {
            $lookup: {
              from: "sites",
              localField: "scenario.siteId",
              foreignField: "_id",
              as: "site",
            },
          },
          { $unwind: "$site" },
          {
            $lookup: {
              from: "indicators",
              localField: "project.indicators._id",
              foreignField: "_id",
              as: "globalIndicatorSettings",
            },
          },
        ]);
      if (solution.length === 1) {
        return solution[0];
      } else {
        throw new Error("Invalid code.");
      }
    },

    async saveSolution({ commit }, solution) {
      await db()
        .collection("solutions")
        .updateOne(
          { _id: solution._id },
          { $set: { notes: solution.notes, published: solution.published } },
        );
      commit("setSolution", solution);
      return solution;
    },

    logOut({ dispatch }) {
      dispatch("resetUserState");
      return app.currentUser ? app.currentUser.logOut() : false;
    },
  },
});
