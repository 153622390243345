<template>
  <div class="usermenu">
    <a
      class="link"
      @mousedown.prevent="() => $store.commit('setLanguage', 'en')"
      >EN</a
    >
    <a
      class="link"
      @mousedown.prevent="() => $store.commit('setLanguage', 'hu')"
      >HU</a
    >
    <a class="link" @mousedown.prevent="startOver">Restart simulation</a>
    <a class="link" v-if="loggedIn()" @mousedown.prevent="logOut">Sign out</a>
  </div>
</template>
<script>
import { currentUser } from "../store";
export default {
  name: "UserMenu",
  methods: {
    loggedIn() {
      const user = currentUser();
      return user && user._profile.identities[0].providerType != "anon-user"
        ? user
        : false;
    },
    logOut: function () {
      const startPage = this.$store.state.code
        ? this.$store.state.code
        : "StartPage";
      this.$store.dispatch("logOut").then(() => {
        this.$router.push({
          path: startPage,
        });
      });
    },
    startOver() {
      this.$store.commit("setCurrentPage", "/");
      this.$store.commit("setCode", null);
      this.$store.commit("setStep", "enterCode");
      this.$store.commit("setSolution", null);
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>
<style lang="scss">
.usermenu {
  display: flex;
  gap: 5px;
  a.link {
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
