<template>
  <div id="app">
    <div class="app-usermenu">
      <UserMenu></UserMenu>
    </div>
    <div id="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UserMenu from "./components/UserMenu.vue";

export default {
  name: "app",
  components: { UserMenu },
};
</script>

<style lang="scss">
@import "./styles/app.scss";

#error,
#info {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  right: 0;
  margin: auto;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

#info {
  z-index: 1003;
}

#error {
  background: red;
  z-index: 1004;
}

.app-usermenu {
  position: absolute;
  top: 20px;
  right: 105px;
  z-index: 1000;
  display: flex;
}

.app-usermenu .left {
  right: initial;
  left: 105px;
}
.app-usermenu a {
  text-align: center;
  flex-basis: 30%;
  text-decoration: underline;
  min-width: 60px;
  font-weight: 500;
}

#app #content {
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.7);
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
}
</style>
