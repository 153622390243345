import Vue from "vue";
import Router from "vue-router";
import { currentUser } from "./store";

Vue.use(Router);

const StartPage = () => import("./routes/StartPage.vue");
const About = () => import("./routes/About.vue");
const Privacy = () => import("./routes/Privacy.vue");

const ExploreSite = () => import("./routes/ExploreSite.vue");
const ImplementationGame = () => import("./routes/ImplementationGame.vue");
const Evaluation = () => import("./routes/Evaluation.vue");
const EvaluationPage = () => import("./routes/EvaluationPage.vue");
const ResetPassword = () => import("./routes/ResetPassword.vue");

const routes = [
  { path: "/ExploreSite", component: ExploreSite },
  { path: "/ImplementationGame", component: ImplementationGame },
  { path: "/Evaluation", component: Evaluation },
  {
    path: "/Solution/:solution",
    component: EvaluationPage,
    meta: { public: true },
  },
  { path: "/ResetPassword", component: ResetPassword, meta: { public: true } },
  { path: "/About", component: About, meta: { public: true } },
  { path: "/Privacy", component: Privacy, meta: { public: true } },
  { path: "/:roomId", component: StartPage, meta: { public: true } },
  { path: "/", component: StartPage, meta: { public: true } },
];

const router = new Router({
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const user = currentUser();
  if (to.matched.some((record) => record.meta.public)) {
    next();
  } else if (
    to.matched.some((record) => record.meta.admin) &&
    user &&
    (user.customData.admin === true ||
      (user.customData.editorForProjects &&
        user.customData.editorForProjects.length > 0))
  ) {
    next();
  } else if (!to.matched.some((record) => record.meta.admin) && user) {
    next();
  } else {
    try {
      next("/");
    } catch (e) {
      console.error(e);
    }
  }
});

export default router;
